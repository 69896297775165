import { default as _91_46_46_46slug_93cPD684fdYvMeta } from "/codebuild/output/src1220425185/src/pages/[mainSchema]/[...slug].vue?macro=true";
import { default as _91childSchema_934iezg8DWkEMeta } from "/codebuild/output/src1220425185/src/pages/[mainSchema]/[childSchema].vue?macro=true";
import { default as indexq1pH8FdlFqMeta } from "/codebuild/output/src1220425185/src/pages/[mainSchema]/index.vue?macro=true";
import { default as _91_46_46_46slug_93w0J3JLeWGaMeta } from "/codebuild/output/src1220425185/src/pages/character/[...slug].vue?macro=true";
import { default as indexIFHToJFeHHMeta } from "/codebuild/output/src1220425185/src/pages/character/index.vue?macro=true";
import { default as _91_46_46_46slug_93rb749zG8R3Meta } from "/codebuild/output/src1220425185/src/pages/dataType/[...slug].vue?macro=true";
import { default as _91childDataType_930QFvdcHqGcMeta } from "/codebuild/output/src1220425185/src/pages/dataType/[childDataType].vue?macro=true";
import { default as indexb9WNTUgIR7Meta } from "/codebuild/output/src1220425185/src/pages/dataType/index.vue?macro=true";
import { default as _91_46_46_46slug_93YobYNkijJiMeta } from "/codebuild/output/src1220425185/src/pages/docs/[...slug].vue?macro=true";
import { default as index9spihYLzKHMeta } from "/codebuild/output/src1220425185/src/pages/docs/index.vue?macro=true";
import { default as indexDUUWMsfHZuMeta } from "/codebuild/output/src1220425185/src/pages/index.vue?macro=true";
import { default as message_45board_46client5J80X2vCHKMeta } from "/codebuild/output/src1220425185/src/pages/message-board.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/codebuild/output/src1220425185/src/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@8.57.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91_46_46_46slug_93Hi2tHsEkWlMeta } from "/codebuild/output/src1220425185/src/pages/prefix/[...slug].vue?macro=true";
import { default as index3Wgw5bxcErMeta } from "/codebuild/output/src1220425185/src/pages/prefix/index.vue?macro=true";
import { default as _91_46_46_46slug_93vbSjxxmYQeMeta } from "/codebuild/output/src1220425185/src/pages/suffix/[...slug].vue?macro=true";
import { default as index8tPHUwi7dPMeta } from "/codebuild/output/src1220425185/src/pages/suffix/index.vue?macro=true";
export default [
  {
    name: "mainSchema-slug",
    path: "/:mainSchema()/:slug(.*)*",
    component: () => import("/codebuild/output/src1220425185/src/pages/[mainSchema]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "mainSchema-childSchema",
    path: "/:mainSchema()/:childSchema()",
    component: () => import("/codebuild/output/src1220425185/src/pages/[mainSchema]/[childSchema].vue").then(m => m.default || m)
  },
  {
    name: "mainSchema",
    path: "/:mainSchema()",
    component: () => import("/codebuild/output/src1220425185/src/pages/[mainSchema]/index.vue").then(m => m.default || m)
  },
  {
    name: "character-slug",
    path: "/character/:slug(.*)*",
    component: () => import("/codebuild/output/src1220425185/src/pages/character/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "character",
    path: "/character",
    component: () => import("/codebuild/output/src1220425185/src/pages/character/index.vue").then(m => m.default || m)
  },
  {
    name: "dataType-slug",
    path: "/dataType/:slug(.*)*",
    component: () => import("/codebuild/output/src1220425185/src/pages/dataType/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "dataType-childDataType",
    path: "/dataType/:childDataType()",
    component: () => import("/codebuild/output/src1220425185/src/pages/dataType/[childDataType].vue").then(m => m.default || m)
  },
  {
    name: "dataType",
    path: "/dataType",
    component: () => import("/codebuild/output/src1220425185/src/pages/dataType/index.vue").then(m => m.default || m)
  },
  {
    name: "docs-slug",
    path: "/docs/:slug(.*)*",
    component: () => import("/codebuild/output/src1220425185/src/pages/docs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "docs",
    path: "/docs",
    component: () => import("/codebuild/output/src1220425185/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1220425185/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "message-board",
    path: "/message-board",
    meta: message_45board_46client5J80X2vCHKMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src1220425185/src/pages/message-board.client.vue").then(m => m.default || m))
  },
  {
    name: "prefix-slug",
    path: "/prefix/:slug(.*)*",
    component: () => import("/codebuild/output/src1220425185/src/pages/prefix/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "prefix",
    path: "/prefix",
    component: () => import("/codebuild/output/src1220425185/src/pages/prefix/index.vue").then(m => m.default || m)
  },
  {
    name: "suffix-slug",
    path: "/suffix/:slug(.*)*",
    component: () => import("/codebuild/output/src1220425185/src/pages/suffix/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "suffix",
    path: "/suffix",
    component: () => import("/codebuild/output/src1220425185/src/pages/suffix/index.vue").then(m => m.default || m)
  }
]