import revive_payload_client_7WsVOteWL0 from "/codebuild/output/src1220425185/src/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@8.57.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VREVYKAODK from "/codebuild/output/src1220425185/src/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@8.57.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_URk1s9tcRU from "/codebuild/output/src1220425185/src/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@8.57.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_EBwdgFXEVP from "/codebuild/output/src1220425185/src/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@8.57.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_FKSSyaGbVp from "/codebuild/output/src1220425185/src/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@8.57.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_tHIE0tmymz from "/codebuild/output/src1220425185/src/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.5.3_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1220425185/src/.nuxt/components.plugin.mjs";
import prefetch_client_ADKuhjyRfx from "/codebuild/output/src1220425185/src/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@8.57.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_ursB5swDkL from "/codebuild/output/src1220425185/src/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@8.57.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zdem4Ar1K1 from "/codebuild/output/src1220425185/src/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.1_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
export default [
  revive_payload_client_7WsVOteWL0,
  unhead_VREVYKAODK,
  router_URk1s9tcRU,
  payload_client_EBwdgFXEVP,
  check_outdated_build_client_FKSSyaGbVp,
  plugin_vue3_tHIE0tmymz,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ADKuhjyRfx,
  chunk_reload_client_ursB5swDkL,
  plugin_zdem4Ar1K1
]